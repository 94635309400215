import React from "react"
import { graphql } from "gatsby"
import BlogPageTemplate from "../templates/BlogListTemplate"

const Blogs = ({
  data: {
    strapiBlogs: { data: strapiBlogData },
    strapiBlogPage: { data: pageData },
  },
}) => {
  return (
    <BlogPageTemplate
      pageContext={{ blogData: strapiBlogData, pageData: pageData.attributes }}
    />
  )
}

export const query = graphql`
  {
    strapiBlogPage {
      data {
        attributes {
          seoTitle
          seoDescription
          seoKeywords
          pageTitle
        }
      }
    }
    strapiBlogs {
      data {
        id
        attributes {
          slug
          title
          description
          publishedAt
          images {
            data {
              attributes {
                localFile {
                  url
                  ext
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          blog_topics {
            data {
              id
              attributes {
                label
                slug
              }
            }
          }
        }
      }
    }
  }
`

export default Blogs
