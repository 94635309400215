import React from "react"
import Layout from "../components/Layout"
import BlogCard from "../components/BlogCard"
import Seo from "../components/SEO"

const BlogPageTemplate = ({ pageContext: { blogData, pageData } }) => {
  return (
    <Layout showContact={false}>
      <Seo
        title={pageData.seoTitle}
        description={pageData.seoDescription}
        keywords={pageData.seoKeywords}
      />
      <section id="blogs" className="section">
        <div className="container">
          <h3 className="title has-text-primary mb-6">{pageData.pageTitle}</h3>
          <div className="columns is-multiline ">
            {blogData.map(({ attributes: blog, id }) => (
              <div className="column is-4-desktop is-half-tablet" key={id}>
                <BlogCard key={id} blog={blog} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPageTemplate
